function ShopCategories () {
  function AssociateCashbackPromotionToogler () {
    $('#cashback-promotions').change(function () {
      if (this.checked) {
        $('#percentage-checkbox').removeClass('hide')
        $('#fixed-rate-checkbox').removeClass('hide')
      } else {
        $('#percentage-checkbox').addClass('hide')
        $('#fixed-rate-checkbox').addClass('hide')
      }
    })
  }

  const initPromotion = (container, toggler) => {
    toggler.change(function () {
      const inputs = container.find(".form-group input[type='number']")
      inputs.prop('disabled', !this.checked)

      if (this.checked) {
        container.removeClass('hide')
      } else {
        container.addClass('hide')
        inputs.val('')
      }
    })

    const toggleAddBtn = () => {
      const maxItems = 5
      const isDisabled = container.find('.form-group').length >= maxItems
      const addBtn = container.find('.add-btn')
      addBtn.prop('disabled', isDisabled)
      if (isDisabled) {
        addBtn.attr('title', `Can't add more than ${maxItems} items`)
      } else {
        addBtn.attr('title', '')
      }
    }

    toggleAddBtn()

    container.on('click', '.add-btn', function () {
      const template = container.children('.form-group').last().clone()
      const inputs = template.find('input')
      const inputCount = parseInt(inputs.first().attr('id').replace(/[^0-9]/g, '')) + 1

      inputs.each((index, input) => {
        $(input).val('')
        const id = $(input).attr('id').replace(/\d+$/, inputCount)
        $(input).attr('id', id)
      })

      template.appendTo(container)
      toggleAddBtn()
    })

    container.on('click', '.remove-btn', function () {
      $(this).closest('.form-group').remove()
      toggleAddBtn()
    })
  }

  const initOneFieldPromotion = (container, toggler) => {
    toggler.change(function () {
      if (this.checked) {
        container.removeClass('hide')
      } else {
        container.addClass('hide')
        container.find(".form-group input[type='number']").val('')
      }
    })

    const toggleAddBtn = () => {
      const maxItems = 10
      const isDisabled = container.find('.form-group').length >= maxItems
      const addBtn = container.find('.add-btn')
      addBtn.prop('disabled', isDisabled)
      if (isDisabled) {
        addBtn.attr('title', `Can't add more than ${maxItems} items`)
      } else {
        addBtn.attr('title', '')
      }
    }

    toggleAddBtn()

    container.on('click', '.add-btn', function () {
      const template = $(this).closest('.form-group').clone()
      const input = template.find('input')
      input.val('')
      const id = input.attr('id').replace(/\d+$/, container.find('input').length + 1)
      input.attr('id', id)
      template.appendTo(container)
      toggleAddBtn()
    })

    container.on('click', '.remove-btn', function () {
      $(this).closest('.form-group').remove()
      toggleAddBtn()
    })
  }

  if (window.location.pathname.includes('shop_categories')) {
    AssociateCashbackPromotionToogler()

    initPromotion($('#percentage-cashback'), $('#enable-percentage-based-cashback'))
    initPromotion($('#discount-promotion'), $('#link_promotional_services'))
    initOneFieldPromotion($('#cashback-fixed-rate'), $('#enable-fixed-rate-cashback'))
    initOneFieldPromotion($('#target-promotion'), $('#link_target_based_stores'))
  }
}

export default ShopCategories
